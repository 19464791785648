<template>
  <v-app class="default-layout">
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<style lang="sass" scoped>
.default-layout
  background: $main-w-color
</style>
